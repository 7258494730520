<template>
    <div class="container">
        <steps-viewer
            :steps="$data.$steps"
            :step="currentStep"
            @click="clickStep"
        />
        <div v-if="currentStep === 0" class="col-12 col-md-10 col-lg-8 mx-auto">
            <div class="d-flex flex-column justify-content-center">
                <div class="d-flex align-items-center justify-content-center">
                    <p class="text-primary h2" data-testid="default-typography">
                        Sube el archivo con el inventario
                    </p>
                    <span class="display-4 mx-3"> 🗃 </span>
                </div>

                <div>
                    <p data-testid="default-typography" class="my-4">
                        Carga de inventario para la
                        {{
                            selectedMerchant.merchantType | merchantInfo("type")
                        }}
                        <span class="font-weight-bold">
                            "{{ selectedMerchant.name }}" </span
                        >. Llena los campos con la guía que encontraras en el
                        <a
                            href="/template_massive_load_inventories.xlsx"
                            target="_blank"
                            rel="noopener"
                        >
                            archivo base </a
                        >. Este proceso reemplazará las unidades del inventario
                        o ingresará el inventario. Recuerda que el archivo debe
                        tener máximo 1000 filas.
                    </p>
                    <span v-if="isLoading" class="text-center text-warning">
                        Cargando...
                    </span>
                </div>
                <inventory-file-loader
                    @loaded="loadInventories"
                    @loading="isLoading = true"
                    @error="isLoading = false"
                />
                <div class="mt-4">
                    <small>
                        Peso máximo: 20MB | Formato permitido: Excel (XLSX)
                    </small>
                </div>
            </div>
        </div>
        <div v-else-if="currentStep === 1" class="d-flex flex-column">
            <div class="d-flex flex-column align-items-center">
                <p
                    data-testid="default-typography"
                    class="my-4 font-weight-bold"
                >
                    Tienes errores en el archivo. A continuación te mostramos
                    una lista de errores por favor corrige tu archivo excel.
                    Cuando hayas corregido el archivo excel da click en "Volver
                    al paso anterior".
                </p>
                <div>
                    <vs-button relief @click="resetItem">
                        Volver al paso anterior
                    </vs-button>
                </div>
            </div>
            <error-viewer
                v-for="(errorItem, index) in errors"
                :key="index"
                :error="errorItem"
                :active="activeError"
                :base="$data.$errorsBase"
                @changeActive="changeActiveError($event)"
            />
        </div>
        <div v-else-if="currentStep === 2">
            <div class="my-3">
                <span>
                    Resumen: inventarios a ingresar
                    {{ inventoriesToInsert.length }} inventarios a
                    {{ overwriteMessage }}
                    {{ inventoriesToUpdate.length }}
                </span>
            </div>
            <div v-if="inventoriesToInsert.length">
                <div class="mb-4">
                    <span class="h3">
                        Inventarios a insertar
                        <span class="text-primary">
                            {{ inventoriesToInsert.length }}
                        </span>
                    </span>
                </div>

                <inventory-manage
                    v-for="(item, index) in inventoriesToInsert"
                    :key="'A' + index"
                    :inventory="item"
                    @remove="removeInventoryToInsert"
                    @update="updateInventoryToInsert"
                />
            </div>
            <div v-if="inventoriesToUpdate.length">
                <div class="my-4">
                    <span class="h3">
                        Inventarios a {{ overwriteMessage }}
                        <span class="text-primary">
                            {{ inventoriesToUpdate.length }}
                        </span>
                    </span>
                </div>

                <inventory-manage
                    v-for="(item, index) in inventoriesToUpdate"
                    :key="'B' + index"
                    :inventory="item"
                    @remove="removeInventoryToUpdate"
                    @update="updateInventoryToUpdate"
                />
            </div>
            <div class="my-4">
                <vs-button
                    class="mt-3 mb-5 col-9 mx-auto"
                    border
                    gradient
                    @click="saveMassiveInventoriesComponent"
                >
                    <span class="h3"> Guardar Cambios </span>
                </vs-button>
            </div>
        </div>
        <div v-else-if="[3, 4].includes(currentStep)">
            <div v-if="isLoading">Procesando ...</div>
            <div v-else class="d-flex flex-wrap">
                <div class="col-12 mb-4">
                    <span class="text-success h2">Resultados</span>
                </div>
                <div class="col-4">
                    <strong class="">
                        Inventarios insertados :
                        <span class="text-success">
                            {{ results.inventoriesInserted.length }}
                        </span>
                    </strong>
                </div>
                <div class="col-4">
                    <strong class="">
                        Inventarios actualizados :
                        <span class="text-success">
                            {{ results.inventoriesUpdated.length }}
                        </span>
                    </strong>
                </div>
                <div class="col-4">
                    <strong class="">
                        Inventarios incrementados :
                        <span class="text-success">
                            {{ results.inventoriesIncremented.length }}
                        </span>
                    </strong>
                </div>

                <div class="col-12 d-flex justify-content-center mt-4">
                    <vs-button @click="resetItem">
                        Iniciar nueva carga
                    </vs-button>
                    <vs-button success @click="downloadJsonComponent">
                        Descargar Resultados JSON ⬇
                    </vs-button>
                </div>
            </div>
        </div>
        <vs-dialog v-model="isOpenUpdateOrIncrement" prevent-close not-close>
            <div class="con-content d-flex flex-column py-4 col-11 mx-auto">
                <div class="my-4">
                    <span class="h4">
                        Hemos encontrado referencias anteriormente registradas
                        por favor indicanos si quieres incrementar o reemplazar
                        las unidades en el inventario.
                    </span>
                </div>

                <div class="d-flex justify-content-around">
                    <button
                        class="btn border-2 btn-info h-min-24 d-flex align-items-center justify-content-center"
                        @click="setOverwrite(true)"
                    >
                        <span class="h3">Reemplazar Stock</span>
                    </button>
                    <button
                        class="btn border-2 btn-primary h-min-24 d-flex align-items-center justify-content-center"
                        @click="setOverwrite(false)"
                    >
                        <span class="h3">Incrementar Stock</span>
                    </button>
                </div>
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { downloadJson } from "@/common/lib/json";

import InventoryFileLoader from "@/components/inventories/InventoryFileLoader.vue";
import InventoryManage from "@/components/inventories/InventoryManage.vue";
import ErrorViewer from "@/components/utils/ErrorViewer.vue";
import StepsViewer from "@/components/utils/StepsViewer.vue";

export default {
    name: "InventoriesMassiveLoad",
    components: {
        InventoryFileLoader,
        StepsViewer,
        ErrorViewer,
        InventoryManage
    },
    data: () => ({
        overwrite: false,
        isLoading: false,
        loaderInstance: null,
        isOpenUpdateOrIncrement: false,
        step: 0,
        $steps: [
            { name: "Sube archivo", enabledClicked: true },
            { name: "Valida errores en archivo" },
            { name: "Verifica el inventario" },
            { name: "Guarda los cambios" }
        ],
        $errorsBase: [
            { name: 'Stock de inventario "stock"', key: "stock" },
            { name: 'Costo de unidad "cost"', key: "cost" },
            { name: 'Código de barras "barcode"', key: "barcode" }
        ],
        currentStep: 0,
        activeError: 0,
        inventoriesToInsert: [],
        inventoriesToUpdate: [],
        errors: [],
        results: {
            inventoriesInserted: [],
            inventoriesUpdated: []
        }
    }),
    computed: {
        ...mapState("commons", ["selectedMerchant"]),
        ...mapGetters("control", ["backgroundColor"]),
        overwriteMessage() {
            return this.overwrite ? "actualizar" : "incrementar";
        }
    },
    watch: {
        selectedMerchant() {
            this.resetItem();
        }
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (
            !(await verifyPageScope(
                "inventory:LoadMassive",
                "/app/inventories",
                true
            ))
        )
            return;
    },
    beforeDestroy() {},
    methods: {
        ...mapActions("inventories", [
            "verifyMassiveInventories",
            "saveMassiveInventories"
        ]),
        async loadInventories(rows) {
            const dialogResult = await this.$swal.fire({
                title: `Vas a cargar ${rows.length} filas? `,
                background: this.backgroundColor,
                icon: "info",
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: "Si",
                cancelButtonText: "No 😖",
                confirmButtonColor: "#02913f",
                cancelButtonColor: "#f07325",
                allowOutsideClick: false
            });
            if (!dialogResult.isConfirmed) {
                window.location.href = this.$route.path;
                return;
            }
            this.verifyMassiveInventoriesComponent(rows);
        },
        async verifyMassiveInventoriesComponent(rows) {
            this.errors = [];
            this.inventoriesToUpdate = [];
            this.inventoriesToInsert = [];
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const result = await this.verifyMassiveInventories({
                    rows,
                    merchantId: this.selectedMerchant.merchantId,
                    merchantType: this.selectedMerchant.merchantType
                });
                if (!result.pass) {
                    this.errors = result.errors;
                    this.activeError = result.errors[0].index;
                    this.currentStep = 1;
                    return;
                }
                this.currentStep = 2;
                this.inventoriesToUpdate = result.inventoriesToUpdate;
                this.inventoriesToInsert = result.inventoriesToInsert;
                this.isOpenUpdateOrIncrement =
                    result.inventoriesToUpdate.length > 0;
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/cash-outputs");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async saveMassiveInventoriesComponent() {
            const dialogResult = await this.$swal.fire({
                title: `Vas a insertar ${this.inventoriesToInsert.length} y a ${this.overwriteMessage} ${this.inventoriesToUpdate.length} unidades en el inventario ?`,
                background: this.backgroundColor,
                icon: "info",
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: "Si",
                cancelButtonText: "No 😖",
                confirmButtonColor: "#02913f",
                cancelButtonColor: "#f07325",
                allowOutsideClick: false
            });
            if (!dialogResult.isConfirmed) {
                return;
            }

            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            this.currentStep = 3;
            try {
                const inventories = [
                    ...this.inventoriesToInsert,
                    ...this.inventoriesToUpdate
                ].map((item, index) => ({
                    index: index + 1,
                    item: {
                        referenceId: Number(item.id),
                        barcode: item.barcode,
                        stock: Number(item.stock),
                        cost: Number(item.cost),
                        price: Number(item.price),
                        priceOffer: Number(item.priceOffer)
                    }
                }));
                this.results = await this.saveMassiveInventories({
                    inventories,
                    overwrite: this.overwrite,
                    merchantId: this.selectedMerchant.merchantId,
                    merchantType: this.selectedMerchant.merchantType
                });
                this.currentStep = 4;
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                this.currentStep = 3;
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        clickStep(step) {
            this.currentStep = step;
        },
        setOverwrite(overwrite) {
            this.overwrite = overwrite;
            this.isOpenUpdateOrIncrement = false;
        },
        changeActiveError(ref) {
            this.activeError = ref;
        },
        resetItem() {
            this.errors = [];
            this.inventoriesToUpdate = [];
            this.inventoriesToInsert = [];
            this.currentStep = 0;
        },
        removeInventoryToInsert({ sku }) {
            const index = this.inventoriesToInsert.findIndex(
                (item) => item.sku === sku
            );
            let inventoriesToInsert = [...this.inventoriesToInsert];
            inventoriesToInsert.splice(index, 1);
            this.inventoriesToInsert = [...inventoriesToInsert];
        },
        updateInventoryToInsert(payload) {
            const index = this.inventoriesToInsert.findIndex(
                (item) => item.sku === payload.sku
            );
            let inventoriesToInsert = [...this.inventoriesToInsert];
            inventoriesToInsert[index] = {
                ...payload
            };
            this.inventoriesToInsert = [...inventoriesToInsert];
        },
        removeInventoryToUpdate({ sku }) {
            const index = this.inventoriesToUpdate.findIndex(
                (item) => item.sku === sku
            );
            let inventoriesToUpdate = [...this.inventoriesToUpdate];
            inventoriesToUpdate.splice(index, 1);
            this.inventoriesToUpdate = [...inventoriesToUpdate];
        },
        updateInventoryToUpdate(payload) {
            const index = this.inventoriesToUpdate.findIndex(
                (item) => item.sku === payload.sku
            );
            let inventoriesToUpdate = [...this.inventoriesToUpdate];
            inventoriesToUpdate[index] = {
                ...payload
            };
            this.inventoriesToUpdate = [...inventoriesToUpdate];
        },
        downloadJsonComponent() {
            downloadJson({
                payload: this.results,
                filename: "massiveLoadInventoriesResult"
            });
        }
    }
};
</script>
