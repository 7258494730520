<template>
    <div>
        <div class="d-flex flex-wrap justify-content-center">
            <div v-if="!!error" class="alert alert-danger" role="alert">
                {{ error }}
            </div>
        </div>
        <div class="d-flex flex-wrap d-flex justify-content-center">
            <label
                class="w-64 d-flex flex-column align-items-center px-4 py-6 btn btn-success rounded text-uppercase"
            >
                <img
                    src="@/assets/images/upload.svg"
                    height="20"
                    width="20"
                    alt="upload file"
                />
                <span class="mt-2 text-base leading-normal">
                    {{
                        selectedFile
                            ? `Archivo seleccionado "${file.name}"`
                            : "Seleccione archivo excel"
                    }}
                </span>
                <input
                    id="application-uploader"
                    :disabled="isLoading"
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv,.xls"
                    autocomplete="off"
                    tabindex="-1"
                    multiple=""
                    data-testid="file-drop-massive-load"
                    class="d-none"
                    @input="uploadButton($event)"
                />
            </label>
        </div>
    </div>
</template>

<script>
import { parseFileMassiveLoadInventories } from "@/common/lib/excel";

export default {
    name: "InventoryFileLoader",
    components: {},
    model: {
        prop: "itemCurrent",
        event: "loaded"
    },
    events: ["loaded", "loading", "error"],
    data: () => ({
        isLoading: false,
        selectedFile: false,
        file: { name: "" },
        error: ""
    }),
    computed: {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        async uploadButton(event) {
            const auxFile = event.target.files || event.dataTransfer.files;
            if (!auxFile.length) return;
            this.file = auxFile[0];
            const fileSize = this.file.size / 1048576;
            if (fileSize > 40) {
                this.error = "Archivo demasiado pesado máximo 40 MB";
                return;
            }
            this.selectedFile = true;
            this.error = "";
            let inventories = [];
            try {
                this.$emit("loading");
                inventories = await parseFileMassiveLoadInventories(this.file);
                this.$emit("loaded", inventories);
            } catch (error) {
                this.$emit("error");
                this.error =
                    "Ha ocurrido un error transformando el archivo, descarge el archivo base oficial, recargue la página e intentelo nuevamente.";
            }
        }
    }
};
</script>
