<template>
    <div class="border-bottom border-info w-100 pb-4">
        <div class="col-12 d-flex justify-content-between align-items-center">
            <div class="d-flex flex-lg-row flex-column">
                <div>
                    <strong>Código de barras {{ inventory.barcode }}</strong>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <vs-button icon border circle @click="toggleActive">
                    {{ isActive ? "➰" : "🔰" }}
                </vs-button>
                <div v-if="enabled" id="actions" class="d-flex">
                    <vs-button danger icon @click="remove">🗑</vs-button>
                </div>
            </div>
        </div>
        <b-collapse
            ref="container"
            :visible="isActive"
            class="d-flex flex-wrap"
        >
            <div
                class="col-lg-2 col-md-3 col-4 d-flex justify-content-center align-items-center py-3"
            >
                <div class="w-28 h-28 p-0">
                    <img
                        class="w-100 h-100"
                        :src="inventory.images[0].url | image"
                        :alt="inventory.images[0].alt"
                        loading="lazy"
                    />
                </div>
            </div>
            <div
                class="col-lg-10 col-md-9 col-8 d-flex flex-column justify-content-around py-3 px-1"
            >
                <div>
                    <div class="col-12 d-flex justify-content-between mb-3">
                        <div class="d-flex flex-column">
                            <router-link
                                :to="`/app/products/detail/${inventory.productId}`"
                                class="btn-a p-0"
                            >
                                <span class="h4 text-center font-weight-normal">
                                    {{ inventory.name }}
                                </span>
                            </router-link>
                            <small>
                                Prod
                                <router-link
                                    :to="`/app/products/detail/${inventory.productId}`"
                                    class="btn-a p-0"
                                >
                                    {{ inventory.productId }}
                                </router-link>
                                | Ref
                                <router-link
                                    :to="`/app/products/detail/${inventory.productId}?reference=${inventory.id}&active=references`"
                                    class="btn-a p-0"
                                >
                                    {{ inventory.id }}
                                </router-link>
                            </small>
                        </div>
                        <div class="d-flex flex-column">
                            <span>Color : {{ inventory.color }}</span>
                            <span>Tamaño : {{ inventory.size }}</span>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-wrap mb-3">
                    <div class="col-6 d-flex flex-column">
                        <span class="text-success"> Precio global actual </span>
                        <span>
                            {{ inventoryComponent.currentGlobalPrice | money }}
                        </span>
                    </div>
                    <div class="col-6 d-flex flex-column">
                        <span class="text-success">
                            Precio final global actual
                        </span>
                        <span>
                            {{
                                inventoryComponent.currentGlobalPriceOffer
                                    | money
                            }}
                        </span>
                    </div>
                    <div class="col-6 d-flex flex-column">
                        <span class="text-success"> Sku de producto </span>
                        <span>
                            {{ inventoryComponent.sku }}
                        </span>
                    </div>
                    <div class="col-6 d-flex flex-column">
                        <span class="text-success">
                            Última actualización de referencia
                        </span>
                        <span>
                            {{ inventory.updatedAt | date }}
                        </span>
                    </div>
                    <div
                        v-if="inventory.currentUnits"
                        class="col-6 d-flex flex-column"
                    >
                        <span class="text-success"> Stock actual </span>
                        <span>
                            {{ inventory.currentUnits }}
                        </span>
                    </div>
                    <div
                        v-if="inventory.currentCost"
                        class="col-6 d-flex flex-column"
                    >
                        <span class="text-success"> Costo actual </span>
                        <span>
                            {{ inventory.currentCost | money }}
                        </span>
                    </div>
                    <div
                        v-if="inventoryComponent.currentPrice"
                        class="col-6 d-flex flex-column"
                    >
                        <span class="text-success"> Precio actual </span>
                        <span>
                            {{ inventoryComponent.currentPrice | money }}
                        </span>
                    </div>
                    <div
                        v-if="inventoryComponent.currentPriceOffer"
                        class="col-6 d-flex flex-column"
                    >
                        <span class="text-success"> Precio final actual </span>
                        <span>
                            {{ inventoryComponent.currentPriceOffer | money }}
                        </span>
                    </div>
                    <div class="col-12 col-lg-6"></div>
                    <div class="col-12 col-lg-6 mt-5">
                        <vs-input
                            v-model="inventoryComponent.stock"
                            border
                            shadow
                            min="1"
                            type="number"
                            label="Stock"
                            class="opacity-false"
                            :state="stateInputDark"
                            :disabled="!enabled"
                            @focus="onFocus"
                            @blur="updateItem"
                        />
                    </div>
                    <div class="col-12 col-lg-6 mt-5">
                        <input-money-component
                            v-model="inventoryComponent.cost"
                            :enabled="enabled"
                            border
                            label="Costo de unidad"
                            :message-error="
                                !isValidCost & introValidators.cost
                                    ? 'Costo no puede ser $0'
                                    : ''
                            "
                            class="w-100 mw-100"
                            @focus="onFocus"
                            @blur="updateItem"
                        />
                    </div>
                    <div class="col-12 col-lg-6 mt-5">
                        <input-money-component
                            v-model="inventoryComponent.price"
                            :enabled="enabled"
                            border
                            label="Precio"
                            class="w-100 mw-100"
                            :message-error="
                                introValidators.price &&
                                !inventoryComponent.price
                                    ? 'El precio no puede ser cero'
                                    : ''
                            "
                            @focus="onFocus"
                            @blur="onBlurPrice"
                        />
                    </div>
                    <div class="col-12 col-lg-6 mt-5">
                        <input-money-component
                            v-model="inventoryComponent.priceOffer"
                            :enabled="enabled"
                            border
                            label="Precio final"
                            class="w-100 mw-100"
                            :message-error="
                                inventoryComponent.priceOffer >
                                inventoryComponent.price
                                    ? 'El precio final debe ser menor que el precio'
                                    : introValidators.priceOffer &&
                                      !inventoryComponent.priceOffer
                                    ? 'El precio final no puede ser cero'
                                    : ''
                            "
                            @focus="onFocus"
                            @blur="onBlurPriceOffer"
                        />
                    </div>
                </div>
            </div>
        </b-collapse>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import InputMoneyComponent from "@/components/utils/InputMoneyComponent.vue";

export default {
    name: "InventoryManage",
    components: { InputMoneyComponent },
    props: {
        inventory: {
            type: Object,
            required: true,
            default: () => ({
                barcode: "null",
                cost: 0,
                stock: 0
            })
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["update", "remove"],
    data: () => ({
        inventoryComponent: {
            barcode: "",
            cost: 0,
            stock: 0
        },
        isActive: false,
        introValidators: {
            cost: false,
            priceOffer: false,
            price: false
        }
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        isValidCost() {
            return this.inventoryComponent.cost > 0;
        }
    },
    watch: {
        inventory(value) {
            this.setInventory(value);
        }
    },
    mounted() {
        this.setInventory(this.inventory);
    },
    methods: {
        remove() {
            this.$emit("remove", { barcode: this.inventory.barcode });
        },
        updateItem() {
            this.$emit("update", this.inventoryComponent);
        },
        setInventory(inventory) {
            this.inventoryComponent = {
                ...inventory
            };
        },
        toggleActive() {
            this.isActive = !this.isActive;
        },
        onFocus() {
            this.$emit("focus");
        },
        onBlurCost() {
            this.introValidators.cost = true;
            this.updateItem();
        },
        onBlurPrice() {
            this.introValidators.price = true;
            this.updateItem();
        },
        onBlurPriceOffer() {
            this.introValidators.priceOffer = true;
            this.updateItem();
        }
    }
};
</script>
